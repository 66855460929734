import React, { Component } from "react"
import Layout from "../components/layout"
import { Link } from "gatsby"
// import Clutch from "../components/clutch"
// import Slider from "react-slick"
import Wordpress from "../images/wordpress/wordpress-top.jpg"
import Process__Step_Sr from "../images/wordpress/dev_icon.png"
import Process__Step_Sr2 from "../images/shopify/best_aaproch.png"
import Process__Step_Sr3 from "../images/shopify/consulting.png"
import migration2 from "../images/wordpress/wordpress_build.jpg"
import Wordpress_plugin from "../images/wordpress/plugin.jpg"
import Wordpress_theme from "../images/wordpress/theme.jpg"
import Wocommerce from "../images/wordpress/woocommerce.jpg"
import Migrate from "../images/wordpress/migration.jpg"

import {Helmet} from "react-helmet/es/Helmet";


const ListLink = props => (
  <li>
    <Link to={props.to}>{props.children}</Link>
  </li>
);


export default class Container extends Component {
  render() {
    return (
      <Layout className="">
        <Helmet>
          <title>
            Contact Us | Get in Touch with Cueblocks </title>
          <meta name="viewport" content="initial-scale=1.0, width=device-width, maximum-scale=1, user-scalable=no"/>
          <meta charSet="utf-8"/>
          <link rel="canonical" href="https://www.cueblocks.com/contact.php"/>
          <meta name="description"
                content="Want to get in touch?  We'd love to hear from you. Contact us now by filling this form."/>
          <meta property="og:site_name" content="Cueblocks"/>
          <meta property="fb:app_id" content="289086684439915"/>
          <meta property="og:url" content="https://www.cueblocks.com/contact.php"/>
          <meta property="og:title" content="Contact Us | Get in Touch with Cueblocks"/>
          <meta property="og:description"
                content="Want to get in touch?  We'd love to hear from you. Contact us now by filling this form."/>
          <meta property="og:image" content="https://www.cueblocks.com/images/cb.jpg"/>

          <meta name="twitter:card" content="summary_large_image"/>
          <meta name="twitter:description"
                content="Want to get in touch?  We'd love to hear from you. Contact us now by filling this form."/>
          <meta name="twitter:title" content="Contact Us | Get in Touch with Cueblocks"/>
          <meta name="twitter:site" content="@cueblocks"/>
          <meta name="twitter:image" content="https://www.cueblocks.com/images/cb.jpg"/>
        </Helmet>
        <article className="static_page wordpress-page">
          <section className="ser_sec_row  creative_design_page ">
            <div className="container">
              <div className="ser_sec_row_lt wdt-40 pd_right_40">
                <h2 className="heading_main">WordPress</h2>
                <div className="ser_sec_row_lt_text">
                  <p>
                    Want to set up your own website but don’t know where to start? The first step is to get in touch with our team of expert developers that know their
                     way around one of the most popular content management systems, WordPress. Keeping your business goals in mind, we build and customize WordPress sites,
                      blogs, and plug-ins to give your audience a user-friendly shopping experience. We ensure that your site is optimized for speed, SEO, and integrated with
                       third-party modules & applications if required. 
                  </p>
                </div>
              </div>
              <div className="ser_sec_row_rt wdt-60 pd_left_40 text-center">
                <img
                  src={Wordpress}
                  alt="Wordpress"
                  className="img-responsive"
                />
              </div>
              <div className="border-light"> </div>
            </div>
          </section>

          <section className=" our-process">
            <div className="container">
              <div className="main-heading text-center">
                <h2 className="heading_main">OUR PROCESS</h2>
              </div>
              <div className="process-steps">
                <div className="steps">
                  <div className="steps-inn">
                    <figure>
                      <img
                        src={Process__Step_Sr}
                        alt="Cue Blocks"
                        className="image_responsive"
                      />
                    </figure>
                    <h3>DEVELOPMENT ENVIRONMENT</h3>
                    <p>
                      Using reliable Version Control Systems and Staging Environments, we ensure that a properly vetted code makes it to your live site, 
                      delivering a high-quality website that meets a global standard. 
                    </p>
                  </div>
                </div>
                <div className="steps">
                  <div className="steps-inn">
                    <figure>
                      <img
                        src={Process__Step_Sr2}
                        alt="Cue Blocks"
                        className="image_responsive"
                      />
                    </figure>
                    <h3>DEFINED APPROACH</h3>
                    <p>
                     With a streamlined communication process, our team formulates the most befitting strategy that aims at getting your site up & running,
                      thereby giving your customers the user experience they look forward to.
                    </p>
                  </div>
                </div>
                <div className="steps">
                  <div className="steps-inn">
                    <figure>
                      <img
                        src={Process__Step_Sr3}
                        alt="Cue Blocks"
                        className="image_responsive"
                      />
                    </figure>
                    <h3>CONSULTATIVE APPROACH</h3>
                    <p>
                      Our experience in the eCommerce industry clubbed with divergent thinking helps us to blend the basic concepts of what’s trending into the code of 
                      your website, giving your site a nudge towards the road to success. 
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className=" motion-graphics right-img fleex">
            <div className="container">
              <div className="print-outer">
                <div className="ser_sec_row_rt pd_left_40 right">
                  <figure>
                    <img
                      src={migration2}
                      alt="WordPress Build From Scratch"
                      className="img-responsive"
                    />
                  </figure>
                </div>
                <div className="ser_sec_row_lt pd_right_20">
                  <h2 className="heading_main">
                   WordPress <br/>Build From Scratch
                  </h2>
                  <div className="ser_sec_row_lt_text">
                    <p>
                      Need a compact yet high-performance website for your business? Our team of experts creates powerful WordPress sites from scratch, 
                      keeping in mind the ever-evolving needs of your business, ensuring security and efficiency at every stage of development. 
                    </p>
                  </div>
                  <Link
                    to="/contact"
                    className="btn main_cta"
                  >
                    Contact us
                  </Link>
                </div>
              </div>
            </div>
          </section>

          <section className=" motion-graphics fleex">
            <div className="container">
              <div className="print-outer">
                <div className="ser_sec_row_rt pd_right_40">
                  <figure>
                    <img
                      src={Wordpress_plugin}
                      alt="WordPress Plugin Development"
                      className="img-responsive"
                    />
                  </figure>
                </div>
                <div className="ser_sec_row_lt pd_left_40">
                  <h2 className="heading_main">
                    WordPress Plugin Development
                  </h2>
                  <div className="ser_sec_row_lt_text">
                    <p>
                      Built-in features not enough to give a smooth user-experience to your customers? Leveraging our extensive knowledge of WordPress, we develop custom plugins that help in extending the core functionality of your site. Customize everything from admin features to the frontend functionality of your site to suit your business goals.

                    </p>
                  </div>
                  <Link to="/contact" className="btn main_cta">
                    contact us
                  </Link>
                </div>
              </div>
            </div>
          </section>

          <section className=" motion-graphics right-img fleex">
            <div className="container">
              <div className="print-outer">
                <div className="ser_sec_row_rt pd_left_40 right">
                  <figure>
                    <img
                      src={Wordpress_theme}
                      alt="Customizing WordPress Theme"
                      className="img-responsive"
                    />
                  </figure>
                </div>
                <div className="ser_sec_row_lt pd_right_20">
                  <h2 className="heading_main">
                    Customizing WordPress Theme
                  </h2>
                  <div className="ser_sec_row_lt_text">
                    <p>
                      Does the overall look & feel of your site appear contrary to your business goals? With WordPress’s theme-customization service, 
                      our team of developers creates bespoke designs by enhancing admin-level functionality to make your site easy-to-manage while looking aesthetically 
                      appealing to your consumers. 
                    </p>
                  </div>
                  <Link
                    to="/contact"
                    className="btn main_cta"
                  >
                    Contact us
                  </Link>
                </div>
              </div>
            </div>
          </section>

          <section className=" motion-graphics fleex">
            <div className="container">
              <div className="print-outer">
                <div className="ser_sec_row_rt pd_right_40">
                  <figure>
                    <img
                      src={Wocommerce}
                      alt="WooCommerce Development From Scratch"
                      className="img-responsive"
                    />
                  </figure>
                </div>
                <div className="ser_sec_row_lt pd_left_40">
                  <h2 className="heading_main">
                   WooCommerce Development From Scratch
                  </h2>
                  <div className="ser_sec_row_lt_text">
                    <p>
                      Planning to establish your business in the eCommerce industry? Let our proficient team of experts help create a responsive eStore for your business.
                       Embedded with high-level security, inventory management, shipping calculator, social media sharing, and more bespoke functionalities, we develop 
                       WooCommerce eStores from scratch that make your brand stand apart from the crowd. 
                    </p>
                  </div>
                  <Link to="/contact" className="btn main_cta">
                    contact us
                  </Link>
                </div>
              </div>
            </div>
          </section>

          <section className=" motion-graphics right-img fleex">
            <div className="container">
              <div className="print-outer">
                <div className="ser_sec_row_rt pd_left_40 right">
                  <figure>
                    <img
                      src={Migrate}
                      alt="Migration to WooCommerce"
                      className="img-responsive"
                    />
                  </figure>
                </div>
                <div className="ser_sec_row_lt pd_right_20">
                  <h2 className="heading_main">
                    Migration to WooCommerce
                  </h2>
                  <div className="ser_sec_row_lt_text">
                    <p>
                      Looking for a high-security eStore for your business? Leveraging the freedom to customize on WooCommerce, one of the most sought-after platforms in the 
                      industry for its flexibility & scalability, we help you to migrate your existing eStore so your business can scale to its full potential while being
                       user-friendly for your consumers.
                    </p>
                  </div>
                  <Link
                    to="/contact"
                    className="btn main_cta"
                  >
                    Contact us
                  </Link>
                </div>
              </div>
            </div>
          </section>

 

     
     
        </article>
      </Layout>
    )
  }
}
